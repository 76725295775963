<template>
  <div class="adhocCharges">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{title}}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-form class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <company-select @chosen="setCompany" v-model="companySelected"></company-select>
              <v-col>
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="Object.keys(companySelected).length === 0"
                  color="primary"
                  @click="showChargeForm()"
                >Add New Charge</v-btn>
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="Object.keys(companySelected).length === 0"
                  color="primary"
                  @click="viewChargesTable(), chargeForm = false"
                >View Charges</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-card v-if="chargeForm" class="mb-4">
        <v-container>
          <h2 class="title">Add a new Recurring Charge for {{companySelected.name}}</h2>
          <v-row class="control columns">
            <div class="column">
              <charge-form
                :newCharge="charge"
                :displaySubmit="true"
                :recurring="true"
                :loading="loading"
                @createCharge="createRecurringCharge()"
              ></charge-form>
            </div>
          </v-row>
        </v-container>
      </v-card>
      <v-card v-if="companyCharges.length > 0" class="mt-4">
        <v-dialog v-model="dialog" max-width="75vw">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Recurring Charge: {{editedItem.reference}}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <charge-form
                  :newCharge="editedItem"
                  :recurring="true"
                  :loading="loading"
                  :editItemId="true"
                ></charge-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-title>
          Recurring Charges for {{companySelected.name}}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="chargesHeaders"
          :items="companyCharges"
          :items-per-page="5"
          :search="search"
          id="data-table"
        >
          <template v-slot:item.total_charge="{ item }">
            <v-chip dark>{{ renderCurrency(item['total_charge']) }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <div v-if="item.id">
              <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
              <v-icon small @click="deleteItem(item)">delete</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import AdhocCharges from "@/services/AdhocCharges.js";
import CompanySelect from "../components/forms/CompanySelect";
import ChargeForm from "../components/forms/ChargeForm";

export default {
  components: {
    ChargeForm,
    CompanySelect
  },
  data() {
    return {
      // Title
      title: "Recurring Charges",
      // Snackbar
      snackbar: false,
      text: "",
      // Company
      companySelected: {},
      // Form
      chargeForm: null,
      charge: {
        company_id: null,
        client: null,
        chargeType: null,
        reference: null,
        clientReference: null,
        UOM: null,
        recurring: null,
        recurringDate: null,
        billableUnits: null,
        tariff: null,
        totalCharge: null,
        chargeCode: null
      },
      // Table Data
      search: "",
      companyCharges: [],
      // Charges Table
      chargesHeaders: [
        { text: "Client", value: "client" },
        { text: "Charge Type", value: "charge_type" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "client_reference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billable_units" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "total_charge" },
        { text: "Charge Code", value: "charge_code" },
        { text: "Recurring", value: "recurring" },
        { text: "Recurring Date", value: "recurring_date" },
        { text: "Actions", value: "action", sortable: false }
      ],
      dialog: false,
      editedItem: []
    };
  },
  methods: {
    // Needed for Company Select
    setCompany(company) {
      this.companySelected = company;
    },
    // Show New Charge Form
    showChargeForm() {
      this.charge.client = this.companySelected.code;
      this.chargeForm = true;
    },
    // Currency
    renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    // Store Recurring Charge
    createRecurringCharge() {
      this.$root.sharedData.startLoading();
      this.charge.company_id = this.companySelected.id;
      AdhocCharges.createRecurringCharge(this.charge)
        .then(() => {
          this.$root.sharedData.finishLoading();
          this.snackbar = true;
          this.text = `Successfully created ${this.charge.reference} Charge for ${this.companySelected.name}.`;
          this.close();
          this.viewChargesTable();
          this.charge = Object.assign({}, this.defaultItem)
          this.charge.client = this.companySelected.code;
        })
        .catch(err => {
          this.$root.sharedData.finishLoading();
          this.snackbar = true;
          this.text = `${err}`;
        });
    },
    viewChargesTable() {
      this.$root.sharedData.startLoading();
      AdhocCharges.getRecurringCharges(this.companySelected.id)
        .then(data => {
          this.companyCharges = data;
          this.$root.sharedData.finishLoading();
          this.snackbar = true;
          if (data.length === 0) {
            this.text = `No Recurring Charges found for ${this.companySelected.name}.`;
          } else {
            this.text = `Successfully loaded Recurring Charges for ${this.companySelected.name}.`;
          }
        })
        .catch(err => {
          this.$root.sharedData.finishLoading();
          this.snackbar = true;
          this.text = `${err}`;
        });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    editItem(item) {
      this.editedIndex = this.companyCharges.indexOf(item);
      this.editedItem = {
        id: item.id,
        company_id: this.companySelected.id,
        client: item.client,
        chargeType: item.charge_type,
        reference: item.reference,
        clientReference: item.client_reference,
        UOM: item.UOM,
        billableUnits: item.billable_units,
        recurring: item.recurring,
        recurringDate: item.recurring_date,
        tariff: item.tariff,
        totalCharge: item.total_charge,
        chargeCode: item.charge_code.toString()
      };
      this.selectedChargeCode = this.editedItem.charge_code;
      this.dialog = true;
    },
    async save() {
      this.$root.sharedData.startLoading();
      AdhocCharges.updateRecurringCharge(this.editedItem)
        .then(() => {
          this.$root.sharedData.finishLoading();
          this.snackbar = true;
          this.text = `Successfully updated ${this.editedItem.reference}.`;
          this.close();
          this.viewChargesTable();
        })
        .catch(err => {
          this.$root.sharedData.finishLoading();
          this.snackbar = true;
          this.text = `${err}`;
        });
    },
    async deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$root.sharedData.startLoading();
        AdhocCharges.deleteRecurringCharge(item)
          .then(() => {
            this.$root.sharedData.finishLoading();
            this.snackbar = true;
            this.text = `Deleted ${item.reference} Services`;
            this.viewChargesTable();
          })
          .catch(err => {
            this.$root.sharedData.finishLoading();
            this.snackbar = true;
            this.text = `${err}`;
          });
      }
    }
  },
  computed: {
    loading: function() {
      return this.$root.sharedData.state.loading;
    }
  },
};
</script>

<style>
</style>